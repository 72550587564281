import queryString from 'query-string'
import React, { createContext, useMemo } from 'react'

export const QueryParamsContext = createContext({
  params: {},
  location: undefined
})

const QueryParamsProvider = props => {
  const { children, location } = props

  const params = useMemo(() => {
    const query = queryString.parse(
      typeof window === 'undefined' ? {} : location.search
    )

    if (!query.sort) {
      query.sort = 'prodRanking'
    }

    if (!query.direction) {
      query.direction = 'desc'
    }

    if (query.color) {
      if (!Array.isArray(query.color)) {
        query.color = [query.color]
      }
    }

    if (query.brand) {
      if (!Array.isArray(query.brand)) {
        query.brand = [query.brand]
      }
    }

    if (query.delivery) {
      if (!Array.isArray(query.delivery)) {
        query.delivery = [query.delivery]
      }
    }

    if (query.shop) {
      if (!Array.isArray(query.shop)) {
        query.shop = [query.shop]
      }
    }

    return query
  }, [location])

  const value = {
    query: params,
    location: location
  }

  return (
    <QueryParamsContext.Provider value={value}>
      {children}
    </QueryParamsContext.Provider>
  )
}

export default QueryParamsProvider
