import React from 'react'
import MobileNavigationProvider from './MobileNavigationProvider'
import QueryParamsProvider from './QueryParamsProvider'

const AllProviders = props => {
  const { children, location, pageContext } = props

  return (
    <QueryParamsProvider location={location}>
      <MobileNavigationProvider navigation={pageContext.navigation} {...props}>
        {children}
      </MobileNavigationProvider>
    </QueryParamsProvider>
  )
}

export default AllProviders
