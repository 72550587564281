import { navigate } from 'gatsby-link'
import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import React, { createContext, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion'

import '../assets/accordion.css'
import MobileHeader from '../components/blocks/MobileHeader'

export const MobileNavigationContext = createContext({
  mobileNavigationStatus: false
})

const MobileNavigationProvider = ({ navigation, children }) => {
  const [mobileNavigationStatus, setMobileNavigationStatus] = useState(false)

  const values = {
    mobileNavigationStatus,
    setMobileNavigationStatus
  }

  const selectAndClose = useCallback(e => {
    const link = e.currentTarget.getAttribute('data-category')
    setMobileNavigationStatus(false)
    setTimeout(() => navigate(link[0] === '/' ? link : '/' + link), 350)
  }, [])

  const toggleMobileNavigation = useCallback(() => {
    setMobileNavigationStatus(!mobileNavigationStatus)
  }, [mobileNavigationStatus])

  return (
    <MobileNavigationContext.Provider value={values}>
      {children}
      <Drawer
        open={mobileNavigationStatus}
        width='80vw'
        onClose={toggleMobileNavigation}
        handler={false}
      >
        <MobileHeader>
          AmberLiving
          <MobileHeader.CloseButton
            onClick={toggleMobileNavigation}
          />
        </MobileHeader>
        <Accordion allowZeroExpanded>
          {
            navigation?.mobileNavigation.map((navItem) => {
              if (!navItem.navigationLinks) {
                return (
                  <AccordionItem key={navItem.id}>
                    <div className='accordion__heading'>
                      <button
                        className='accordion__button'
                        data-category={navItem.link}
                        onClick={selectAndClose}
                      >
                        {navItem.name}
                      </button>
                    </div>
                  </AccordionItem>
                )
              }
              return (
                <AccordionItem key={navItem.id}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>{navItem.name}</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {
                      navItem.navigationLinks.map((navItem) => (
                        <div key={navItem.id} className='accordion__heading'>
                          <button
                            className='accordion__button'
                            data-category={navItem.link}
                            onClick={selectAndClose}
                          >
                            {navItem.name}
                          </button>
                        </div>
                      ))
                    }
                  </AccordionItemPanel>
                </AccordionItem>
              )
            })
          }
        </Accordion>
      </Drawer>
    </MobileNavigationContext.Provider>
  )
}

export default MobileNavigationProvider
