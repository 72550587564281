import styled from 'styled-components'

const CloseButton = styled('div')`
  position: absolute;
  height: 100%;
  top: 0;
  width: 36px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 16px;
    background-color: #000000;
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 2px;
    background-color: #000000;
    transform: rotate(45deg);
  }
`
export default CloseButton
