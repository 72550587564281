import styled from 'styled-components'
import CloseButton from './CloseButton'

const MobileHeader = styled('div')`
  position: relative;
  padding: 18px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

MobileHeader.CloseButton = CloseButton

export default MobileHeader
