exports.components = {
  "component---src-templates-about-page-template-index-js": () => import("./../../../src/templates/aboutPageTemplate/index.js" /* webpackChunkName: "component---src-templates-about-page-template-index-js" */),
  "component---src-templates-blog-page-template-index-js": () => import("./../../../src/templates/blogPageTemplate/index.js" /* webpackChunkName: "component---src-templates-blog-page-template-index-js" */),
  "component---src-templates-category-page-template-index-js": () => import("./../../../src/templates/categoryPageTemplate/index.js" /* webpackChunkName: "component---src-templates-category-page-template-index-js" */),
  "component---src-templates-clickout-js": () => import("./../../../src/templates/clickout.js" /* webpackChunkName: "component---src-templates-clickout-js" */),
  "component---src-templates-impressum-page-template-index-js": () => import("./../../../src/templates/impressumPageTemplate/index.js" /* webpackChunkName: "component---src-templates-impressum-page-template-index-js" */),
  "component---src-templates-index-page-template-index-js": () => import("./../../../src/templates/indexPageTemplate/index.js" /* webpackChunkName: "component---src-templates-index-page-template-index-js" */),
  "component---src-templates-landing-page-template-index-js": () => import("./../../../src/templates/landingPageTemplate/index.js" /* webpackChunkName: "component---src-templates-landing-page-template-index-js" */),
  "component---src-templates-legal-page-template-index-js": () => import("./../../../src/templates/legalPageTemplate/index.js" /* webpackChunkName: "component---src-templates-legal-page-template-index-js" */),
  "component---src-templates-not-found-page-template-index-js": () => import("./../../../src/templates/notFoundPageTemplate/index.js" /* webpackChunkName: "component---src-templates-not-found-page-template-index-js" */),
  "component---src-templates-trend-page-template-index-js": () => import("./../../../src/templates/trendPageTemplate/index.js" /* webpackChunkName: "component---src-templates-trend-page-template-index-js" */)
}

